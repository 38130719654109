import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`後の動画でも話していますが、自分で曲を作る時は、この動画のように「自分の曲の完成形」をお手本にすることはできませんので、実際は「同じ曲調の、同じ楽器構成の曲」を探してお手本にします。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      